/* Global Styles */
:root {
    --primary-color: #17a2b8;
    --dark-color: #343a40;
    --light-color: #f4f4f4;
    --danger-color: #dc3545;
    --success-color: #28a745;
  }
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    background-color: #fff;
    color: #333;
  }
  
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
  
  ul {
    list-style: none;
  }
  
  img {
    width: 100%;
  }
  
  /* Utilities */
  .container {
    max-width: 1100px;
    margin: auto;
    overflow: hidden;
    padding: 0 2rem;
    margin-top: 6rem;
    margin-bottom: 3rem;
  }
  
  /* Text Styles*/
  .x-large {
    font-size: 4rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  
  .large {
    font-size: 3rem;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  
  .lead {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-primary {
    color: var(--primary-color);
  }
  
  .text-dark {
    color: var(--dark-color);
  }
  
  /* Navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 2rem;
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
    border-bottom: solid 1px var(--primary-color);
    opacity: 0.9;
  }
  
  .navbar ul {
    display: flex;
  }
  
  .navbar a {
    color: rgb(0, 0, 0);
    padding: 0.45rem;
    margin: 0 0.25rem;
  }
  
  .navbar a:hover {
    color: var(--primary-color);
  }
  
  .navbar .welcome span {
    margin-right: 0.6rem;
  }
  
  /* Landing Page */
  .landing {
    position: relative;
    background: url('../src/img/showcase.jpg') no-repeat center center/cover;
    height: 100vh;
  }

  /* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

.form input[type='submit'],
button {
  font: inherit;
}

.form .social-input {
  display: flex;
}

.form .social-input i {
  padding: 0.5rem;
  width: 4rem;
}

.form .social-input i.fa-twitter {
  color: #38a1f3;
}
.form .social-input i.fa-facebook {
  color: #3b5998;
}
.form .social-input i.fa-instagram {
  color: #3f729b;
}
.form .social-input i.fa-youtube {
  color: #c4302b;
}
.form .social-input i.fa-linkedin {
  color: #0077b5;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table th {
  background: var(--light-color);
}